/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import styles from "./AboutContainer.module.scss";
import Image from "next/image";
import { ProfileInfoDataInterface } from "../../ProfilesInfo/profileTypes";
import { Images } from "@/utils/constants/imageRelativePaths";
import { USER_PROFILE_STRINGS } from "../../strings";

const AboutContainer = ({
  userData,
}: {
  userData: ProfileInfoDataInterface;
}) => {
  const { EXPERIENCE, LANGUAGES } = USER_PROFILE_STRINGS;
  return (
    <div className={styles.aboutContainer}>
      <h3>About me</h3>
      <p>{userData?.profile?.about}</p>
      <ul className={styles.listWrapper}>
        <li className={styles.listContentWrapper}>
          <Image src={Images.IC_BRIEFCASE} alt="exp" height={26} width={26} />
          <div className={styles.textWrapper}>
            <h3>{EXPERIENCE}</h3>
            <p>{userData?.profile?.totalExperience} year</p>
          </div>
          <div className={styles.circleContainer}></div>
        </li>
        <li className={styles.listContentWrapper}>
          <Image src={Images.IC_LANGUAGES} alt="lang" height={26} width={26} />
          <div className={styles.textWrapper}>
            <h3>{Images.LANGUAGES}</h3>
            <p>{userData?.profile?.preferredLanguage.join(", ")}</p>
          </div>
          <div className={styles.circleContainer}></div>
        </li>
      </ul>
    </div>
  );
};

export default AboutContainer;
