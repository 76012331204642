"use client";

import { IC_BACK_ICON } from "@/utils/imageRelativePaths";
import Image from "next/image";
import { useRouter } from "next/navigation";
import styles from "./BackButton.module.scss";

const BackButton = ({ customClass = "" }: { customClass?: string }) => {
  const router = useRouter();
  const handleBack = () => {
    router.back();
  };
  return (
    <div
      className={`${styles.back_button} ${customClass}`}
      onClick={() => handleBack()}
    >
      <Image src={IC_BACK_ICON} alt="back btn" height={22} width={22} />
    </div>
  );
};

export default BackButton;
