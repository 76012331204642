/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-unresolved */
import React, { useContext } from "react";
import styles from "./NameContainer.module.scss";
import { Images, RUPEE_WHITE_ICON } from "@/utils/imageRelativePaths";
import Image from "next/image";
import { ProfileInfoDataInterface } from "../../ProfilesInfo/profileTypes";
import { USER_PROFILE_STRINGS } from "../../strings";
import { AstroImages } from "@/modules/Astro/constants";
import { AppContext, chatMemberProfile } from "@/context/AppContext";
import { startCall } from "@/services/call.service";
import { CurrencyType } from "@/types/common.types";
import { useRouter } from "next/navigation";

const NameContainer = ({
  userData,
}: {
  userData: ProfileInfoDataInterface;
}) => {
  const router = useRouter();
  const { FOLLOWERS, FOLLOWING, VIEWS } = USER_PROFILE_STRINGS;
  const context = useContext(AppContext);
  const { token } = context?.auth ?? {};
  const {
    setPopups,
    zegoClient,
    privateCallSocket,
    setPrivateCallData = () => null,
    userData: callUser,
    auth,
    setToastData,
    walletBalance,
    userData: currUserData,
    chatData,
    setChatData,
  } = context ?? {};

  const handleCallClick = () => {
    const callerUser = {
      ...userData.profile,
      photo: userData.profile.profilePic,
    };
    if (!token) {
      setPopups &&
        setPopups({
          showLoginPopup: true,
        });
      return;
    }
    if (callUser && auth?.userId && zegoClient && privateCallSocket)
      void startCall(
        zegoClient,
        callerUser,
        () => {
          setPrivateCallData({
            callPopup: true,
            caller: {
              ...callUser,
              photo: callUser?.profilePic,
              userId: auth?.userId ?? "",
            },
            contentOwner: callerUser,
          });
        },
        setToastData,
        privateCallSocket,
        setPrivateCallData
      );
  };

  const handleChatClick = () => {
    if (!token) {
      setPopups &&
        setPopups({
          showLoginPopup: true,
        });
      return;
    }

    if (
      (currUserData?.diamonds ?? 0) <=
      userData?.profile?.pricePerMinuteChatInInr &&
      (walletBalance?.astroVCard ?? 0) === 0
    ) {
      setChatData &&
        setChatData({ ...chatData, isInsufficientBalancePopup: true });

      return;
    }

    userData &&
      setChatData &&
      setChatData({
        initiator: {
          attributes: {
            isOnline: true,
          },
          user: currUserData as unknown as chatMemberProfile,
        },
        receiver: {
          attributes: {},
          user: {
            photo: userData?.profile?.profilePic,
            name: userData?.profile?.name,
            userId: userData?.profile?.userId,
            pricePerMinuteChatInInr: userData?.profile?.pricePerMinuteChatInInr,
          },
        },
        currencyType:
          (walletBalance?.astroVCard ?? 0) > 0
            ? CurrencyType.ASTROVCARDS
            : CurrencyType.DIAMONDS,
      });

    return router.push(
      `/chat-intake-form?receiverUserId=${userData?.profile?.userId}`
    );
  };

  return (
    <>
      <div className={styles.nameContainer}>
        <div className={styles.callButtons}>
          {userData?.profile?.permissions?.receivePaidDM && (
            <button className={styles.callBtn} onClick={handleChatClick}>
              <div className={styles.callIcon}>
                <Image src={AstroImages.CHAT} alt={""} /> Chat
              </div>
              <div className={styles.priceSection}>
                {(walletBalance?.astroVCard ?? 0) > 0 ? (
                  "Free"
                ) : (
                  <>
                    <Image
                      src={RUPEE_WHITE_ICON}
                      alt="badge"
                      height={6}
                      width={6}
                    />
                    {userData?.profile?.pricePerMinuteChatInInr}/min
                  </>
                )}
              </div>
            </button>
          )}

          {userData?.profile?.permissions?.receivePrivateCall && (
            <button className={styles.callBtn} onClick={handleCallClick}>
              <div className={styles.callIcon}>
                <Image src={AstroImages.CALL} alt={""} /> CALL
              </div>
              <div className={styles.priceSection}>
                <>
                  <Image
                    src={RUPEE_WHITE_ICON}
                    alt="badge"
                    height={6}
                    width={6}
                  />
                  {userData?.profile?.pricePerMinuteInInr}/min
                </>
              </div>
            </button>
          )}
        </div>

        <div className={styles.userImageWrapper}>
          <div className={styles.userImageContainer}>
            <Image
              src={userData?.profile?.profilePic || Images.DEFAULT_AVATAR}
              alt="profile"
              height={114}
              width={114}
              className={styles.userImage}
              style={{ objectFit: "cover" }}
            />
          </div>
          <Image
            src={Images.IC_BADGE}
            alt="badge"
            height={39}
            width={94}
            className={styles.badge}
          />
          <div className={styles.userLevel}>
            LV{userData?.profile?.creatorLevel}
          </div>
        </div>
        <div className={styles.userNameWrapper}>
          <h2>
            {userData?.profile?.name}
            {userData?.profile?.country === "India" ? (
              <Image
                src={Images.IC_FLAG}
                alt="country"
                height={18}
                width={18}
              />
            ) : (
              <></>
            )}
          </h2>
          <ul>
            <li>{userData?.profile?.username}</li>
            <li>{userData?.profile?.creatorCategories?.[0]?.name}</li>
          </ul>
          <p>
            <Image
              src={Images.IC_CALENDAR}
              alt="calendar"
              width={15}
              height={15}
            />
            Joined {userData?.profile?.platformJoiningDate}
          </p>
        </div>
        <ul className={styles.viewersWrapper}>
          <li className={styles.viewersWrapperElement}>
            <span>{userData?.userMeta?.followersCount}</span> {FOLLOWERS}
          </li>
          <li className={styles.viewersWrapperElement}>
            <span>{userData?.userMeta?.followingCount}</span> {FOLLOWING}
          </li>
          <li className={styles.viewersWrapperElement}>
            <span>{userData?.userMeta?.viewsCount}</span> {VIEWS}
          </li>
        </ul>
      </div>
    </>
  );
};

export default NameContainer;
