import Image from "next/image";
import { ChingariButton } from "chingari-components";
import styles from "./EmptyPlaceHolder.module.scss";
import { NO_DATA } from "@/utils/imageRelativePaths";
import { EMPTY_DATA, RETRY_BUTTON_TITLE } from "@/utils/strings";

interface EmptyPlaceHolderProps {
  icon?: string;
  heading?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

const EmptyPlaceHolder = ({
  icon = NO_DATA,
  heading = EMPTY_DATA,
  width = 24,
  height = 24,
  onClick,
}: EmptyPlaceHolderProps) => {
  return (
    <div className={styles.innerWrapper}>
      <Image
        src={icon}
        alt="EmptyPlaceHolder-icon"
        width={width}
        height={height}
        priority={true}
      />
      <p className={styles.heading}>{heading}</p>
      {onClick && (
        <ChingariButton
          title={RETRY_BUTTON_TITLE}
          onClick={onClick}
          className={styles.button}
        />
      )}
    </div>
  );
};

export default EmptyPlaceHolder;
