import {
  CategoryData,
} from "../Enum";

import {
  ErrorType,
  RoomResponse,
  ROOM_TYPE,
  UserData,
} from "@/types/common.types";

enum FEED_TYPE {
  POPULAR = "popular",
  NEW = "new",
  FOLLOWING = "following",
  NEARBY = "nearby",
}

interface RoomReqBody {
  start: number;
  size: number;
  roomType: ROOM_TYPE;
  location?: number[];
  category?: string;
  subCategory?: string;
}

interface SetRoomReqBody {
  roomId: number;
  roomType: ROOM_TYPE;
  category: string;
  subCategory: string;
}

interface RoomsPageData {
  data: RoomResponse[];
  user?: UserData | null;
  error?: ErrorType["code"];
  categories?: CategoryData[];
  nonAuthToken?: string;
}

export { type RoomReqBody, type RoomsPageData, type SetRoomReqBody, FEED_TYPE };
