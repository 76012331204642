import React, { useEffect, useState } from "react";
import styles from "./CategoryContainer.module.scss";
import { ProfileInfoDataInterface } from "../../ProfilesInfo/profileTypes";

const CategoryContainer = ({
  userData,
}: {
  userData: ProfileInfoDataInterface | undefined;
}) => {
  const [data, setData] = useState<ProfileInfoDataInterface | undefined>(
    undefined
  );

  useEffect(() => {
    setData(userData);
  }, [userData]);

  if (data?.profile?.specializations?.length ?? 0 > 0)
    return (
      <div className={styles.categoryContainer}>
        <h3>Specializaion</h3>
        <ul className={styles.categoryList}>
          {data?.profile?.specializations.map((elem) => (
            <li className={styles.categoryListElement}>{elem}</li>
          ))}
        </ul>
      </div>
    );

  return null;
};

export default CategoryContainer;
