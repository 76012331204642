/* eslint-disable import/no-unresolved */
"use client";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "next/navigation";
import { AppContext } from "@/context/AppContext";
import { Loader } from "chingari-components";
import { getNonAuthToken } from "@/services/common.service";
import { ErrorType } from "@/types/common.types";
import { ProfileInfoDataInterface } from "@/modules/Astro/UserProfile/ProfilesInfo/profileTypes";
import ProfilesInfo from "@/modules/Astro/UserProfile/ProfilesInfo";
import { getUserInfo } from "@/services/profile.service";

const ProfileInfoPage = () => {
  const params = useParams();
  const userName = params?.roomType;
  const context = useContext(AppContext);
  const { nonAuth, setNonAuth } = context ?? {};
  const { token } = context?.auth ?? {};
  const [user, setUser] = useState<ProfileInfoDataInterface>();
  const [error, setError] = useState<ErrorType["code"] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setUserDataWithLoader = async (tokenData?: string) => {
    try {
      const nonAuthToken = tokenData ? tokenData : nonAuth;
      setIsLoading(true);
      const data = await getUserInfo(
        userName as string,
        token ? token : (nonAuthToken as string)
      );
      setUser(data);
    } catch (err) {
      console.log(err);
      setError((err as ErrorType)?.code || 500);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!nonAuth && !token && !isLoading) {
      setIsLoading(true);
      void getNonAuthToken().then((data) => {
        setNonAuth && setNonAuth(data);
        if (!user && userName) {
          void setUserDataWithLoader(data);
        }
      });
    } else if (!user && (token || nonAuth) && !isLoading && userName) {
      void setUserDataWithLoader();
    }
  }, [userName, nonAuth]);

  const handleActionButton = () => {
    void setUserDataWithLoader();
  };

  const renderInfo = () => {
    let component = (
      <ProfilesInfo userData={user as ProfileInfoDataInterface} />
    );

    if (isLoading) {
      component = (
        <Loader
          loaderContainerStyles={{
            top: 0,
            left: 0,

            background: "rgba(0, 0, 0, 0.7)",
          }}
        />
      );
    }
    return component;
  };

  if (!userName) {
    return <div>Loading...</div>;
  }
  return <>{renderInfo()}</>;
};

export default ProfileInfoPage;
