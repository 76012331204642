import { ReactNode } from "react";
import { TabItem } from "../components/Tabs";
import { ROOM_TYPE, UserData } from "../Enum";
import {
  AUDIO_ROOM_TAB_CONSTANTS,
  INITIAL_ROOM_REQ_BODY,
  LIVE_ROOM_TAB_CONSTANTS,
} from "./rooms.constants";
import { FEED_TYPE, RoomReqBody } from "./rooms.types";

const getTabItems = (
  Component: ReactNode,
  roomType: ROOM_TYPE,
  user?: UserData
): Array<TabItem> => {
  let TABS =
    roomType === ROOM_TYPE.LIVE
      ? LIVE_ROOM_TAB_CONSTANTS
      : AUDIO_ROOM_TAB_CONSTANTS;
  if (!user) {
    TABS = TABS.filter(({ key }) => key !== FEED_TYPE.FOLLOWING);
  }
  return TABS.map((item) => ({ ...item, children: Component }));
};

const getInitialReqRoomBody = (
  roomType: ROOM_TYPE = ROOM_TYPE.LIVE
): RoomReqBody => ({
  start: 0,
  size: 10,
  roomType,
});

const generateRoomReqBody = (
  roomType: ROOM_TYPE = ROOM_TYPE.LIVE,
  category?: string,
  subCategory?: string
): RoomReqBody => ({
  ...INITIAL_ROOM_REQ_BODY,
  roomType,
  category,
  subCategory,
});

export { getTabItems, getInitialReqRoomBody, generateRoomReqBody };
