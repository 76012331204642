import { ReactNode } from "react";
import styles from "./Tabs.module.scss";
import { CategoryData, SubCategoryData } from "../../Enum";
import Image from "next/image";
import { Images } from "@/utils/constants/imageRelativePaths";

interface TabProps {
  selectedTab?: string;
  selectedSubTab?: string;
  className?: string;
  classNameSelected?: string;
  onTabChange?: (val: string) => void;
  onSubTabChange: (val: string) => void;
  categoryOptions?: CategoryData[];
  subCategories?: SubCategoryData[];
  filterAllowed?: boolean;
}

export type TabItem = {
  key: string;
  label: ReactNode;
  children: ReactNode;
  disabled?: boolean;
};

const Tabs = ({
  selectedTab = "",
  selectedSubTab = "All",
  onTabChange,
  onSubTabChange,
  className = styles.Option,
  classNameSelected = styles.Selected,
  subCategories,
  filterAllowed,
}: TabProps) => {
  const renderSubCategories = () => (
    <div className={styles.filterWraper}>
      <div className={styles.filterContainner}>
        {/* logo Section */}
        {filterAllowed && (
          <div className={styles.filterIcon}>
            <Image
              src={Images.funnel}
              alt="Filtr Icon"
              width={20}
              height={20}
            />
          </div>
        )}
        {/* Filter Options Section */}
        <div className={styles.FilterOptions}>
          {subCategories?.map((cl: SubCategoryData, index) =>
            cl.name === "Quiz" || cl.name === "following" ? null : (
              <button
                key={index}
                className={`${className} ${
                  selectedSubTab === cl.name ? `${classNameSelected}` : ""
                }`}
                onClick={() => {
                  onSubTabChange(cl.name);
                }}
              >
                {cl.name}
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );

  return <>{renderSubCategories()}</>;
};

export default Tabs;
