import RoomCardSkeleton from "./RoomCardSkeleton/RoomCardSkeleton";
import styles from "./RoomSkeleton.module.scss";

const RoomSkeleton = () => {
  return (
    <div className={styles.roomSkeleton}>
      {Array(10)
        .fill(null)
        .map((_, index) => (
          <RoomCardSkeleton key={`room-card-skeleton-${index}`} />
        ))}
    </div>
  );
};

export default RoomSkeleton;
