import styles from "./skeleton.module.scss";
import { SkeletonTypes } from "./skeleton.types";

interface SkeletonProps {
  animate?: boolean;
  type?: SkeletonTypes;
  children?: React.ReactNode;
}

const Skeleton = ({ animate = true, type, children }: SkeletonProps) => {
  return (
    <div
      className={`${styles.skeleton} ${animate ? styles.animate : ""} ${
        type ? styles[type as unknown as string] : ""
      }`}
    >
      {children && children}
    </div>
  );
};

export default Skeleton;
