import { captialize } from "@/utils/helper";
import { FEED_TYPE, RoomReqBody } from "./rooms.types";

const LIVE_ROOM_TAB_CONSTANTS = [
  {
    key: FEED_TYPE.POPULAR,
    label: captialize(FEED_TYPE.POPULAR),
  },
  {
    key: FEED_TYPE.NEW,
    label: captialize(FEED_TYPE.NEW),
  },
  {
    key: FEED_TYPE.NEARBY,
    label: captialize(FEED_TYPE.NEARBY),
  },
];

const AUDIO_ROOM_TAB_CONSTANTS = [
  {
    key: FEED_TYPE.POPULAR,
    label: captialize(FEED_TYPE.POPULAR),
  },
  {
    key: FEED_TYPE.NEARBY,
    label: captialize(FEED_TYPE.NEARBY),
  },
];

const FEED_MAX_SIZE = 10;

const INITIAL_ROOM_REQ_BODY: Omit<RoomReqBody, "type" | "roomType"> = {
  start: 0,
  size: FEED_MAX_SIZE,
};

const NO_DATA_MESSAGE = "We couldn't find any live rooms";

export {
  LIVE_ROOM_TAB_CONSTANTS,
  INITIAL_ROOM_REQ_BODY,
  NO_DATA_MESSAGE,
  AUDIO_ROOM_TAB_CONSTANTS,
};
