import Image from "next/image";
import { ChingariButton } from "chingari-components";
import styles from "./error.module.scss";
import { IC_ERROR } from "@/utils/imageRelativePaths";
import { ERROR, RETRY_BUTTON_TITLE } from "@/utils/strings";

interface ErrorPlaceHolderProps {
  icon?: string;
  heading?: string;
  description?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}

const ErrorPlaceHolder = ({
  icon = IC_ERROR,
  heading = ERROR.heading,
  description = ERROR.decription,
  width = 24,
  height = 24,
  onClick,
}: ErrorPlaceHolderProps) => {
  return (
    <div className={styles.innerWrapper}>
      <Image
        src={icon}
        alt="error-icon"
        width={width}
        height={height}
        priority={true}
      />
      <p className={styles.heading}>{heading}</p>
      <p className={styles.description}>{description}</p>
      {onClick && (
        <ChingariButton
          className={styles.button}
          title={RETRY_BUTTON_TITLE}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default ErrorPlaceHolder;
