"use client";

import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import Image from "next/image";
import PostsContainer from "./components/PostsContainer/PostsContainer";
import RoomSkeleton from "./components/RoomSkeleton/RoomSkeleton";
import { RoomsPageData } from "./rooms.types";
import { generateRoomReqBody } from "./rooms.helper";
import { NO_DATA_MESSAGE } from "./rooms.constants";
import styles from "./RoomsExplore.module.scss";
import { ErrorType, RoomResponse, ROOM_TYPE } from "@/types/common.types";
import { getRoomFeed } from "@/services/rooms.service";
import {
  ERROR_IMAGE,
  IC_BACK_ICON,
  NETWORK_ERROR_IMAGE,
} from "@/utils/imageRelativePaths";
import Tabs from "../components/Tabs";
import ErrorPlaceHolder from "@/common/components/ErrorPlaceHolder/ErrorPlaceHolder";
import EmptyPlaceHolder from "@/common/components/EmptyPlaceHolder/EmptyPlaceHolder";
import { NETWORK_ERROR_MESSAGE } from "@/utils/strings";
import { useParams } from "next/navigation";

const Rooms = ({
  data = [],
  user,
  error: _error,
  categories,
  nonAuthToken,
}: RoomsPageData) => {
  const { roomType = "" } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [roomsFeedData, setRoomsFeedData] = useState<RoomResponse[]>(data);
  const [selectedCategory, setSelectedCategory] = useState<string>(
    categories?.[0]?.name ?? ""
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>(
    categories?.[0]?.subCategories?.[0]?.name ?? ""
  );
  const [error, setError] = useState<ErrorType["code"] | undefined>(_error);
  const router = useRouter();

  const fetchData = async (refresh = false) => {
    setError(undefined);
    try {
      const roomReqBody = generateRoomReqBody(
        roomType as ROOM_TYPE,
        selectedCategory.length > 0 ? selectedCategory : "Astrology",
        selectedSubCategory.length > 0 ? selectedSubCategory : "All"
      );
      if (!refresh) {
        roomReqBody.start = roomsFeedData?.length;
      }
      console.log(roomReqBody);
      const res = await getRoomFeed(
        { token: user?.token ? user.token : nonAuthToken },
        roomReqBody
      );
      if (res?.data?.length < roomReqBody?.size) {
        setHasMore(false);
      }
      if (!refresh) {
        const roomIds = roomsFeedData?.map(
          ({ liveRoom: { userId } }) => userId
        );
        res.data = res.data?.filter(
          ({ liveRoom: { userId } }) => !roomIds?.includes(userId)
        );
      }
      setRoomsFeedData((prev) => [
        ...(refresh ? [] : prev),
        ...(res?.data || []),
      ]);
    } catch (err) {
      console.error(err);
      console.log(err);
      setError((err as ErrorType)?.code || 500);
    }
  };

  const fetchDataWithLoader = async () => {
    setIsLoading(true);
    await fetchData(true);
    setIsLoading(false);
  };

  const onCategoryChange = (val: string) => {
    setSelectedCategory(val);
    setSelectedSubCategory(
      categories?.filter(({ name }) => name === val)?.[0]?.subCategories?.[0]
        ?.name ?? ""
    );
  };

  const onSubCategoryChange = (val: string) => {
    setSelectedSubCategory(val);
  };

  const handleActionButton = () => {
    void fetchDataWithLoader();
  };

  useEffect(() => {
    setHasMore(true);
    void fetchDataWithLoader();
  }, [selectedCategory, selectedSubCategory]);

  const renderContent = () => {
    let component = roomsFeedData.length ? (
      <PostsContainer
        hasMore={hasMore}
        data={roomsFeedData}
        fetchData={fetchData}
        userId={user?._id}
        category={selectedCategory}
        subCategory={selectedSubCategory}
      />
    ) : (
      <EmptyPlaceHolder
        heading={NO_DATA_MESSAGE}
        width={300}
        height={300}
        onClick={handleActionButton}
      />
    );

    if (isLoading) {
      component = <RoomSkeleton />;
    }
    if (error) {
      const isNetWorkError = error === 500;

      component = (
        <ErrorPlaceHolder
          icon={isNetWorkError ? ERROR_IMAGE : NETWORK_ERROR_IMAGE}
          height={320}
          width={320}
          onClick={handleActionButton}
          heading={isNetWorkError ? undefined : NETWORK_ERROR_MESSAGE}
          description={isNetWorkError ? undefined : ""}
        />
      );
    }

    return component;
  };
  const handleBack = () => {
    void router.push("/");
  };

  return (
    <div className={styles.exploreWrapper}>
      <Tabs
        onTabChange={onCategoryChange}
        onSubTabChange={onSubCategoryChange}
        selectedTab={selectedCategory}
        selectedSubTab={selectedSubCategory}
        subCategories={categories?.[0]?.subCategories}
      />
      <div className={styles.contentWrapper}>
        <div className={styles.back_button} onClick={() => handleBack()}>
          <Image src={IC_BACK_ICON} alt="back btn" height={22} width={22} />
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default Rooms;
