/* eslint-disable import/no-unresolved */
import React from "react";
import NameContainer from "../components/NameContainer";
import AboutContainer from "../components/AboutContainer";
import CategoryContainer from "../components/CategoryContainer";
import { ProfileInfoDataInterface } from "./profileTypes";
import BackButton from "@/common/components/BackButton";

const ProfilesInfo = ({ userData }: { userData: ProfileInfoDataInterface }) => {
  return (
    <>
      <BackButton />
      <NameContainer userData={userData} />
      <AboutContainer userData={userData} />
      <CategoryContainer userData={userData} />
    </>
  );
};

export default ProfilesInfo;
