import Skeleton from "@/common/components/Skeleton/Skeleton";
import { SkeletonTypes } from "@/common/components/Skeleton/skeleton.types";
import styles from "./RoomCardSkeleton.module.scss";

const RoomCardSkeleton = () => {
  return (
    <div className={styles.roomCardSkeleton}>
      <div className={styles.roomInfo}>
        <Skeleton type={SkeletonTypes.LABEL} />
        <div>
          <Skeleton type={SkeletonTypes.TITLE} />
          <Skeleton type={SkeletonTypes.TITLE} />
          <div className={styles.userStatsWrapper}>
            <Skeleton type={SkeletonTypes.AVATAR} />
            <Skeleton type={SkeletonTypes.LABEL} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCardSkeleton;
