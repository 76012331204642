export const USER_PROFILE_STRINGS = {
  FOLLOWERS: "Followers",
  FOLLOWING: "Following",
  VIEWS: "Views",
  ABOUT_ME: "About Me",
  EXPERIENCE: "Experience",
  LANGUAGES: "Languages",
  EDIT_PROFILE: "Edit Profile",
  HELP: "Help",
};

export const ERROR = {
  heading: "OOPS !",
  decription: "Please try again after sometime.",
};

export const RETRY_BUTTON_TITLE = "Try again";
